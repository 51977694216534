<template v-slot:header>
  <el-row>
    <el-col :xl="6" :lg="6" :md="7" :sm="10" :xs="24">
      <div class="grid-content bg-purple user-info">
        <el-row>
          <div class="user-info-content" style="text-align: left;">
            <el-col :span="24">
              <div style="text-align: left;padding: 0.5em">
                <span>账户信息</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div style="text-align: left;padding: 0.5em">
                <el-avatar size="small" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
              </div>
            </el-col>
            <el-col :span="16">
              <div style="padding: 0.5em">
                <span>您好, {{userInfo.userAuthAccount}}</span>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="horizontal-line"></div>
            </el-col>
            <el-col :span="8">
              <div style="padding: 0.5em">
                <span>企业名称</span>
              </div>
            </el-col>
            <el-col :span="16">
              <div style="padding: 0.5em">
                <span>{{userInfo.companyName}}</span>
              </div>
            </el-col>
<!--            <el-col :span="8">-->
<!--              <div style="padding: 0.5em">-->
<!--                <span>手机号码</span>-->
<!--              </div>-->
<!--            </el-col>-->
<!--            <el-col :span="16">-->
<!--              <div style="padding: 0.5em">-->
<!--                <span>{{ 18207929292 }}</span>-->
<!--              </div>-->
<!--            </el-col>-->
<!--            <el-col :span="24">-->
<!--              <div style="padding-top: 5em;padding-bottom: 0.5em;text-align: left;padding-left: 0.5em">-->
<!--                <span>修改密码</span>-->
<!--              </div>-->
<!--            </el-col>-->
          </div>
        </el-row>
      </div>
    </el-col>
    <el-col :xl="18" :lg="18" :md="17" :sm="14" :xs="24">
      <div class="grid-content bg-purple order-info">
        <div class="order-info-title">
          <el-col :span="24" class="order-info-title">
            <span>订单信息</span>
          </el-col>
        </div>
        <el-table :height="500" :data="tableData">
          <el-table-column prop="saasProductName" label="产品名称" width="150" align="center"></el-table-column>
          <el-table-column prop="orderNo" label="订单号" width="180"></el-table-column>
<!--          <el-table-column prop="address" label="规格名称" width="100"></el-table-column>-->
          <el-table-column prop="orderStatus" label="订单状态" width="120"></el-table-column>
          <el-table-column prop="orderPayPrice" label="金额" width="120"></el-table-column>
          <el-table-column prop="orderType" label="订单类型" width="120"></el-table-column>
          <el-table-column prop="orderPayTime" label="支付时间" width="140"></el-table-column>
          <el-table-column prop="orderBeginTime" label="开通时间" width="140"></el-table-column>
          <el-table-column prop="orderEndTime" label="到期时间" width="140"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
          <el-table-column prop="" label="操作" width="140" align="center"></el-table-column>
        </el-table>

        <el-pagination background layout="prev,pager,next" :total="total" :current-page="currentPage" :page-size="pageSize"></el-pagination>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import api from '@/api/member'
export default {
  name: "index",
  components: {
  },
  data() {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      userInfo: {}
    }
  },
  mounted() {
    this.loadUserInfo();
    this.loadOrderInfo();
  },
  methods: {
    loadUserInfo() {
        this.userInfo = this.$store.state.userInfo.userInfo;
        console.log(this.userInfo);
    },
    loadOrderInfo() {
      let userInfo = this.$store.state.userInfo.userInfo;
      api.getOrderInfo({'saasUserId': userInfo.saasUserId}).then((res) => {
        console.log(res);
        this.tableData = res.records;
        this.total = res.total;
        // console.log(res);
        // if(res.data.code !== 200) {
        //   this.$message.error(res.data.message);
        //   return;
        // }
        // this.tableData = res.data.data.records;
        // console.elog(this.tableData)
      })
    }
  }
}
</script>

<style scoped>
  .user-info {
    border: 1px solid #474747;
    margin: 0.1em;
  }

  span {
    font-size: 14px;
  }

  .order-info-title {
    text-align: left;
    line-height: 50px;
    padding-left: 1.5em;
    font-weight: bold;
  }

  .order-info {
    border: 1px solid #474747;
    margin: 0.1em;
  }

  .horizontal-line {
    border: 1px solid #e3e0e0;
    width: 98%;
    margin-left: 1%;
  }
</style>