// import http from "../utils/axios";
import {get} from "@/utils/axios"

// const getOrderInfo = data => get('api-common/order/getOrderInstanceList', data);

/*const  getOrderInfo = data => {
    return http({
        url: "api-common/order/getOrderInstanceList",//接口
        method: "get",
        data: data
    })
}*/

const getOrderInfo = p => get('api-common/order/getOrderInstanceList', p);

export default {
    getOrderInfo
}